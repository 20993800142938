<template>
  <div id="dashboard" class="pa-4">
    <v-container fluid>
      <div class="d-flex justify-space-between mb-5">
        <h2>Dashboard</h2>
      </div>
    </v-container>
    <upload-video-modal
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
    />
  </div>
</template>

<script>
 
import UploadVideoModal from '@/components/UploadVideoModal'

export default {
  data: () => ({
    loading: true,
    dialog: false,
  }),
  components: {
    UploadVideoModal,
  },
  mounted() {}
}
</script>

<style lang="scss">
.card {
  background: white !important;
}
</style>
